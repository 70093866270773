<template>
  <div
    v-if="renderComponent"
    class="wrap"
  >
    <div class="header-container">
      <b-select-validation
        v-model="myPromo.warehouse_id"
        required
        vid="warehouse"
        :label="$tc('warehouse', 1)"
        size="is-small"
        hide-details
        style="min-width: 150px"
        @input="warehouseChanged"
      >
        <template>
          <option
            v-for="opt in warehouses"
            :key="opt.id"
            :value="opt.id"
          >
            {{ opt.country.country }} - {{ opt.currency.code }}
          </option>
        </template>
      </b-select-validation>
      <MonthPicker
        v-model="slide_date_range"
        min-date="01/01/2020"
        :max-date="maxDate"
      />
    </div>
    <hr class="mt-0 mb-4">

    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <b-tabs
          v-model="activeSite"
          class="mb-0"
          @input="slideSiteChange"
        >
          <b-tab-item
            v-for="site, index in selected_sites"
            :key="site.id"
            :value="`${index}`"
          >
            <span slot="header">
              <img
                :src="'/img/sites/' + site.id + '.svg'"
                width="20"
                height="20"
                alt
                class="mr-2"
              >
              {{ site.label }}
            </span>
            <b-loading
              v-model="slides_loading"
              :is-full-page="false"
            />
            <template v-if="!slides_loading">
              <ValidationObserver
                v-for="(slide, k) in myPromo.slides[site.id]"
                ref="observer"
                v-slot="{ handleSubmit }"
                :key="`slide_${site.id}_${k}`"
                slim
              >
                <div class="columns is-multiline is-mobile mt-2">
                  <div
                    class="column is-5-tablet is-12-mobile"
                    :class="{ 'bg-deleted': slide.expired }"
                  >
                    <b-datepicker-validation
                      v-model="myPromo.slides[site.id][k].starts_at"
                      :label="$t('starts_at')"
                      rules="required"
                      :vid="'starts_at' + site.id"
                      :min-date="new Date()"
                      format="YYYY-MM-DD HH:mm:ss"
                      :placeholder="placeholders.date.time"
                      time-picker
                    />
                  </div>

                  <div
                    class="column is-5-tablet is-12-mobile"
                    :class="{ 'bg-deleted': slide.expired }"
                  >
                    <b-datepicker-validation
                      v-model="myPromo.slides[site.id][k].ends_at"
                      :label="$t('expire_at')"
                      rules="required"
                      :vid="'ends_at' + site.id"
                      format="YYYY-MM-DD HH:mm:ss"
                      :min-date="
                        getExpireAtMinDate(myPromo.slides[site.id][k].starts_at)
                      "
                      :placeholder="placeholders.date.time"
                      time-picker
                    />
                  </div>

                  <div
                    class="column is-2-tablet is-12-mobile"
                    :class="{ 'bg-deleted': slide.expired }"
                  >
                    <b-input-validation
                      v-model="myPromo.slides[site.id][k].rank"
                      :label="$t('rank') + ' (' + $t('optional') + ')'"
                      :placeholder="placeholders.number['1']"
                      input-type="number"
                    />
                  </div>
                  <b-tabs
                    class="column is-12 is-flex-wrap-nowrap mb-0"
                    :class="
                      $device.mobile ? 'paddingless' : 'paddingless-right'
                    "
                    :vertical="!$device.mobile"
                  >
                    <b-tab-item
                      v-for="lang in site.languages"
                      :key="'lang-' + lang.iso"
                      header-class="mb-4"
                    >
                      <template slot="header">
                        <img
                          :src="'/img/langs/' + lang.iso + '.svg'"
                          width="18"
                          height="18"
                          class="mr-2"
                        >
                        {{ lang.label }}
                      </template>
                      <div class="columns is-multiline is-mobile ml-2">
                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="
                              myPromo.slides[site.id][k].langs[lang.iso][
                                'title'
                              ]
                            "
                            :rules="
                              getValidation(
                                myPromo.slides[site.id][k].langs,
                                'title'
                              )
                            "
                            :vid="`title_${site.id}_${k}_${lang.iso}`"
                            :label="$t('title')"
                            :placeholder="placeholders.slide.title"
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="
                              myPromo.slides[site.id][k].langs[lang.iso][
                                'description'
                              ]
                            "
                            :vid="`description_${site.id}_${k}_${lang.iso}`"
                            :label="
                              $t('description') + ' (' + $t('optional') + ')'
                            "
                            :placeholder="placeholders.slide.description"
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="
                              myPromo.slides[site.id][k].langs[lang.iso]['url']
                            "
                            :rules="
                              getValidation(
                                myPromo.slides[site.id][k].langs,
                                'url'
                              )
                            "
                            :vid="`url_${site.id}_${k}_${lang.iso}`"
                            :label="$t('url')"
                            :placeholder="placeholders.slide.url"
                          />
                        </div>

                        <div class="column is-6-tablet is-12-mobile">
                          <b-input-validation
                            v-model="
                              myPromo.slides[site.id][k].langs[lang.iso][
                                'button'
                              ]
                            "
                            :vid="`button_${site.id}_${k}_${lang.iso}`"
                            :label="$t('button') + ' (' + $t('optional') + ')'"
                            :placeholder="placeholders.slide.button"
                          />
                        </div>

                        <div class="column is-12">
                          <div
                            v-if="
                              myPromo.slides[site.id][k].langs[lang.iso].img
                            "
                            class="upload-img-container"
                          >
                            <img
                              :src="
                                getImgURL(
                                  myPromo.slides[site.id][k].langs[lang.iso].img
                                )
                              "
                            >

                            <span
                              class="delete-icon"
                              @click="
                                myPromo.slides[site.id][k].langs[
                                  lang.iso
                                ].img = null
                              "
                            >
                              <b-icon
                                icon="close"
                                size="is-small"
                              />
                            </span>
                          </div>
                          <ImageUpload
                            v-else
                            :images="
                              myPromo.slides[site.id][k].langs[lang.iso][
                                'image'
                              ]
                            "
                            :allow-multiple="false"
                            @updateFiles="
                              files =>
                                (myPromo.slides[site.id][k].langs[lang.iso][
                                  'image'
                                ] = files[0])
                            "
                          />
                        </div>

                        <div class="column py-0">
                          <b-button
                            type="is-primary"
                            class="mr-4"
                            :disabled="
                              save_slide_loading == k ||
                                !(
                                  $can('update', 'catalog.deals') ||
                                  $can('create', 'catalog.deals')
                                )
                            "
                            :loading="save_slide_loading == k"
                            @click="
                              handleSubmit(() =>
                                saveSlide(myPromo.slides[site.id][k], k)
                              )
                            "
                          >
                            {{ $t("save") }}
                          </b-button>

                          <b-button
                            v-if="myPromo.slides[site.id][k].slide_id"
                            type="is-danger"
                            outlined
                            :disabled="
                              save_slide_loading == k ||
                                deleteLoading ==
                                myPromo.slides[site.id][k].slide_id ||
                                !$can('delete', 'catalog.deals')
                            "
                            :loading="
                              deleteLoading ==
                                myPromo.slides[site.id][k].slide_id
                            "
                            @click="
                              confirmDeleting(
                                site.id,
                                myPromo.slides[site.id][k].slide_id,
                                k
                              )
                            "
                          >
                            {{ $t("delete") }}
                          </b-button>
                        </div>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                  <div class="column is-12">
                    <hr class="mt-0">
                  </div>
                </div>
              </ValidationObserver>
            </template>

            <div class="is-flex is-justify-content-flex-end">
              <b-button
                v-if="$can('create', 'catalog.deals')"
                type="is-primary"
                outlined
                @click="addSlide(site.id)"
              >
                {{ $t("add_slide") }}
              </b-button>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ImageUpload from "@/components/forms/elements/ImageUpload";
import MonthPicker from "@/components/forms/elements/MonthPicker";
export default {
  components: {
    ImageUpload,
    MonthPicker
  },
  data() {
    return {
      warehouses: [],
      sites: [],
      save_slides_loading: false,
      slides_loading: false,
      deleteLoading: null,
      save_slide_loading: null,
      renderComponent: true,
      changedWarehouse: false,
      activeSite: "0",
      selected_sites: [],
      slide_date_range: null,
      selectedSlidesSiteId: null,
      slides: [],
      myPromo: {
        warehouse_id: null,
        slides: []
      }
    };
  },
  computed: {
    maxDate() {
      return moment()
        .add(1, "Y")
        .format("YYYY/MM/DD");
    }
  },
  watch: {
    slide_date_range(newVal) {
      let month = moment(newVal).format("MM");
      let year = moment(newVal).format("YYYY");
      this.myPromo.slides = [];
      this.getSlides(year, month);
    }
  },
  created() {
    // calling getSlide with no params to set empty slides
    this.getSlides();
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
      setTimeout(this.renderComponent = true,500);
    },
    getValidation(langs, lang_iso, vid) {
      let required = true;
      for (const [iso, lang] of Object.entries(langs)) {
        if (lang_iso == iso) {
          if (lang[vid]) {
            required = false;
            break;
          }
        } else {
          if (!!lang.title && !!lang.url) {
            required = false;
            break;
          }
        }
      }
      return required ? "required" : "";
    },
    confirmDeleting(site_id, id, index) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deleteSlide(site_id, id, index)
      });
    },
    deleteSlide(site_id, id, index) {
      this.deleteLoading = id;
      this.$axios
        .delete("slides/" + id)
        .then(() => {
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
          this.myPromo.slides[site_id].splice(index, 1);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.deleteLoading = null));
    },
    getExpireAtMinDate(date) {
      return date ? moment(date).format("YYYY-MM-DD") : null;
    },
    slideSiteChange(number) {
      this.selectedSlidesSiteId = this.sites[number].id;
      let month, year;
      if (this.slide_date_range) {
        month = moment(this.slide_date_range).format("MM");
        year = moment(this.slide_date_range).format("YYYY");
      }
      this.myPromo.slides = [];
      this.getSlides(year, month);
    },
    warehouseChanged() {
      this.selectedSlidesSiteId = null;
      this.changedWarehouse = true;
      let month, year;
      if (this.slide_date_range) {
        month = moment(this.slide_date_range).format("MM");
        year = moment(this.slide_date_range).format("YYYY");
      }
      this.myPromo.slides = [];
      this.getSlides(year, month);
    },
    setSlides() {
      let arr = {};
      let i;
      if (this.slides.length > 0) {
        this.myPromo.warehouse_id = this.slides[0].warehouse_id;
        for (i = 0; i < this.slides.length; i++) {
          const element = this.slides[i];
          arr[element.id] = [];
          for (let j = 0; j < element.slides.length; j++) {
            const slide = element.slides[j];
            let obj = {
              starts_at: slide.starts_at
                ? moment
                    .parseZone(slide.starts_at.datetime)
                    .format("YYYY-MM-DD HH:mm:ss")
                : null,
              ends_at: slide.ends_at
                ? moment
                    .parseZone(slide.ends_at.datetime)
                    .format("YYYY-MM-DD HH:mm:ss")
                : null,
              rank: slide.rank,
              expired: slide.expired,
              slide_id: slide.id,
              langs: {}
            };
            for (let k = 0; k < slide.trans.length; k++) {
              const trans = slide.trans[k];
              obj.langs[trans.lang_iso] = {
                title: trans.title || null,
                url: trans.url || null,
                description: trans.description || null,
                button: trans.button || null,
                img: trans.img || null,
                image: trans.image ? [trans.image] : []
              };
            }
            arr[element.id].push(obj);
          }
        }
        this.slidesMode = "edit";
      } else {
        let selectedDateRange = moment(this.slide_date_range).startOf("month");
        let now = moment().startOf("day");
        let diff = now.diff(selectedDateRange, "M");

        let diffDays = now.diff(selectedDateRange, "D");
        for (i = 0; i < this.selected_sites.length; i++) {
          arr[this.selected_sites[i].id] = [];
          let j;
          let obj = {
            starts_at: !this.slide_date_range
              ? null
              : diff == 0 && diffDays >= 0
              ? moment(
                  selectedDateRange.format("YYYY-MM") +
                    "-" +
                    moment().format("DD")
                ).startOf("day")
              : moment(selectedDateRange.format("YYYY-MM") + "-01").startOf(
                  "day"
                ),
            ends_at: null,
            rank: null,
            langs: {}
          };

          if (obj.starts_at)
            obj.ends_at = moment(obj.starts_at)
              .add(1, "M")
              .startOf("month");

          for (j = 0; j < this.selected_sites[i].languages.length; j++) {
            obj.langs[this.selected_sites[i].languages[j].iso] = {
              title: this.selected_sites[i].languages[j].url || null,
              url: this.selected_sites[i].languages[j].url || null,
              img: this.selected_sites[i].languages[j].img || null,
              description:
                this.selected_sites[i].languages[j].description || null,
              button: this.selected_sites[i].languages[j].button || null,
              image: this.selected_sites[i].languages[j].image
                ? [this.selected_sites[i].languages[j].image]
                : []
            };
          }
          arr[this.selected_sites[i].id].push(obj);
        }
        this.slidesMode = "create";
      }
      this.myPromo.slides = JSON.parse(JSON.stringify(arr));
    },
    addSlide(site_id) {
      let selectedDateRange = moment(this.slide_date_range).startOf("month");
      let now = moment().startOf("day");
      let diff = now.diff(selectedDateRange, "M");

      let diffDays = now.diff(selectedDateRange, "D");

      let index = this.selected_sites.findIndex(item => item.id === site_id);
      let obj = {
        starts_at: !this.slide_date_range
          ? null
          : diff == 0 && diffDays >= 0
          ? moment(
              selectedDateRange.format("YYYY-MM") + "-" + moment().format("DD")
            ).startOf("day")
          : moment(selectedDateRange.format("YYYY-MM") + "-01").startOf("day"),
        ends_at: null,
        rank: null,
        langs: {}
      };

      if (obj.starts_at)
        obj.ends_at = moment(obj.starts_at)
          .add(1, "M")
          .startOf("month");

      for (let j = 0; j < this.selected_sites[index].languages.length; j++) {
        const element = this.selected_sites[index].languages[j];
        obj.langs[element.iso] = {
          title: this.selected_sites[index].languages[j].title || null,
          url: this.selected_sites[index].languages[j].url || null,
          description:
            this.selected_sites[index].languages[j].description || null,
          button: this.selected_sites[index].languages[j].button || null,
          image: this.selected_sites[index].languages[j].image
            ? this.selected_sites[index].languages[j].image
            : []
        };
      }
      this.myPromo.slides[site_id].push(obj);
    },
    getSlides(year, month) {
      this.slides_loading = true;
      if (year && month) {
        let enpoint = `slides?year=${year}&month=${month}`;
        if (this.myPromo.warehouse_id)
          enpoint += `&warehouse_id=${this.myPromo.warehouse_id}`;
        if (this.selectedSlidesSiteId)
          enpoint += `&site_id=${this.selectedSlidesSiteId}`;
        this.$axios
          .get(enpoint)
          .then(({ data }) => {
            this.slides = data.list;
            this.sites = data.filters.sites;
            this.warehouses = data.filters.warehouses;
            if (!this.myPromo.warehouse_id) {
              this.myPromo.warehouse_id = this.warehouses[0].id;
            }
              let arr = [];
              for (let i = 0; i < this.sites.length; i++) {
                try {
                  let obj = Object.assign({}, this.sites[i]);
                  arr.push(obj);
                } catch (e) {
                  //console.error(e);
                }
              }
              this.selected_sites = arr;

            if (!this.selectedSlidesSiteId) {
              // Select first site in tabs
              this.activeSite = "0";
              this.selectedSlidesSiteId = this.selected_sites[0].id;

            }
            let checkSiteId = data.filters.sites.map(a => a.id).includes(parseInt(this.selectedSlidesSiteId));
            if (this.selectedSlidesSiteId && !checkSiteId) {
              // Select first site in tabs
              this.selectedSlidesSiteId = data.filters.sites[0].id;
              this.activeSite = "0";
              // this.getSlides(year, month);
            }
            
            if (this.changedWarehouse) {
              this.activeSite = "0";
              this.forceRerender();
              this.changedWarehouse = false;
            }
            this.setSlides();
          })
          .catch(e => this.clientError(e))
          .finally(() => {
            this.slides_loading = false;
          });
      } else {
        this.setSlides();
        this.slides_loading = false;
      }
    },
    saveSlide(slide, index) {
      this.save_slide_loading = index;
      let formData = new FormData();
      formData.append("warehouse_id", this.myPromo.warehouse_id);
      formData.append("site_id", this.selectedSlidesSiteId);
      if (slide.ends_at)
        formData.append(
          "ends_at",
          moment(slide.ends_at).format("YYYY-MM-DD HH:mm:ss")
        );
      if (slide.starts_at)
        formData.append(
          "starts_at",
          moment(slide.starts_at).format("YYYY-MM-DD HH:mm:ss")
        );
      if (slide.rank) formData.append(`rank`, slide.rank);
      let langsKeys = Object.keys(slide.langs);
      for (let k = 0; k < langsKeys.length; k++) {
        const lang = slide.langs[langsKeys[k]];
        if (lang.url || lang.title)
          formData.append(`trans[${k}][lang_iso]`, langsKeys[k]);
        if (lang.url) formData.append(`trans[${k}][url]`, lang.url);
        if (lang.title) formData.append(`trans[${k}][title]`, lang.title);
        if (lang.description)
          formData.append(`trans[${k}][description]`, lang.description);
        if (lang.button) formData.append(`trans[${k}][button]`, lang.button);
        if (lang.image !== undefined && !Array.isArray(lang.image))
          formData.append(`trans[${k}][image]`, lang.image);
      }
      let endpoint = `slides?site_id=${this.selectedSlidesSiteId}`;
      let mode;
      if (slide.slide_id) {
        endpoint = `slides/${slide.slide_id}?site_id=${this.selectedSlidesSiteId}`;
        formData.append("_method", "PUT");
        mode = "edit";
      }
      if (this.slide_date_range) {
        let month = moment(this.slide_date_range).format("MM");
        let year = moment(this.slide_date_range).format("YYYY");
        endpoint += `&year=${year}&month=${month}`;
      }
      this.$axios({
        method: "POST",
        url: endpoint,
        data: formData
      })
        .then(({ data }) => {
          this.$root.notify(
            this.$t(
              mode == "edit" ? "successfully_updated" : "successfully_saved"
            ),
            "is-success"
          );
          this.slides = data.list;
          this.setSlides();
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.save_slide_loading = null));
    },
    saveSlides() {
      this.save_slides_loading = true;
      let formData = new FormData();
      let count = 0;
      formData.append("warehouse_id", this.myPromo.warehouse_id);
      let keys = Object.keys(this.myPromo.slides);
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        for (let j = 0; j < this.myPromo.slides[element].length; j++) {
          const slide = this.myPromo.slides[element][j];

          formData.append(`slides[${count}][site_id]`, element);
          if (slide.slide_id)
            formData.append(`slides[${count}][slide_id]`, slide.slide_id);
          if (slide.rank) formData.append(`slides[${count}][rank]`, slide.rank);
          if (slide.ends_at)
            formData.append(
              `slides[${count}][ends_at]`,
              moment(slide.ends_at).format("YYYY-MM-DD HH:mm:ss")
            );
          if (slide.starts_at)
            formData.append(
              `slides[${count}][starts_at]`,
              moment(slide.starts_at).format("YYYY-MM-DD HH:mm:ss")
            );

          let langsKeys = Object.keys(slide.langs);
          for (let k = 0; k < langsKeys.length; k++) {
            const lang = slide.langs[langsKeys[k]];
            if (lang.url && lang.title) {
              formData.append(
                `slides[${count}][trans][${k}][lang_iso]`,
                langsKeys[k]
              );
              formData.append(`slides[${count}][trans][${k}][url]`, lang.url);
              formData.append(
                `slides[${count}][trans][${k}][title]`,
                lang.title
              );
              formData.append(
                `slides[${count}][trans][${k}][image]`,
                lang.image
              );
            }
          }
          count++;
        }
      }
      this.$axios({
        method: "POST",
        url: `slides`,
        data: formData
      })
        .then(res => {
          this.closeDrawer("main", res);
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.save_slides_loading = false));
    },
    getImgURL(slideImg) {
      return `${slideImg.url}${slideImg.path}${slideImg.filename}-w800h400@2x.${slideImg.version}.jpg`;
    }
  }
};
</script>

